import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imagePaths from "../providers/imagePaths";

export default function SeccionCaruselPets() {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    return <div className="seccion-carusel-pets">
        <div className="backgound-sliders">
            <div className="bl"></div>
            <div className="rs"></div>
        </div>
        <h2>This is how your furry friend will look like!</h2>
        <Carousel
            responsive={responsive}
            arrows={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            showDots={true}
            renderDotsOutside={true}
        >
            {
                Object.values(imagePaths).map(
                    (image, index) => <div key={index} className="carusel-pets" style={{ backgroundImage: `url("${image}")` }}></div>
                )
            }
        </Carousel>
    </div>
}