//import imgBan from '../assets/images/furgoneta.webp'
import HiperText from './HiperText'
import Resenas from './Resenas'

export default function SeccionResenas() {
    return <div className="cont-seccion-resenas">
        <div className="resenas-izquierda">
            {/*<img src={ imgBan } alt='TLC Grooming' />**/}
            <HiperText />
        </div>
        <div className="resenas-derecha">
            <Resenas/>
        </div>
    </div>
}