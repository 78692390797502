export const dataReviews = [
    {
        name: 'Adam Lanham',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_a0ce1c72035c98f23de0c3a5551a3ad2.jpg',
        date: '1706404772',
        url: 'https://www.google.com/maps/contrib/116660442187322214478/reviews',
        rev: 'My go to dog boarding place! my husky (apollo)and german shepard(zeus) love it when they are left at TLC.they treat your dogs like they are there own, i highly recommend.'
    },
    {
        name: 'Juliette Llanes Medina',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_2399214e4990c47a4dc0d8be531bc643.jpg',
        date: '1706212917',
        url: 'https://www.google.com/maps/contrib/109714399236073845517/reviews',
        rev: ''
    },
    {
        name: 'Bella',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_6abfcde04489fff8e1e701986d61a6ca.jpg',
        date: '1702763889',
        url: 'https://www.google.com/maps/contrib/113614289161734216089/reviews',
        rev: 'Amazing service and great grooming'
    },
    {
        name: 'Kip Goulder',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_f2215f9aa76eaeec3f34c0b0c62bc17f.jpg',
        date: '1699572818',
        url: 'https://www.google.com/maps/contrib/107044687314135631451/reviews',
        rev: 'My dog is way more pics excited to go to daycare than any other activity/food/etc. Great for grooming too. And a very friendly staff!'
    },
    {
        name: 'Emily Standley (Socialbuzzhive Blog)',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_38fb4e6b2a66d76b369f5c84a12959c5.jpg',
        date: '1686671563',
        url: 'https://www.google.com/maps/contrib/109500999440051773009/reviews',
        rev: 'TLC does a wonderful job of communicating and has a great variety of services for dog and cat grooming. They have both in store and mobile services which is so convenient for busy people.The groomer we had was very nice and gentle and did a wonderful job grooming both our dog and cat who had never been groomed before.We look forward to using them again very soon!'
    },
    {
        name: 'Juliana Rolle',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_d38a729a70f55500e14f4169ca24c989.jpg',
        date: '1679599963',
        url: 'https://www.google.com/maps/contrib/108560243569620289480/reviews',
        rev: "Very professional groomers. I called one morning, they had availability that afternoon and they came to the marina where I stayed. The lady (sorry I forgot her name) came and collected Oliver and put him in the van where she did the grooming service on the spot. After 1 hour, she called and walked out to me where I was overly impressed, excited and thankful for Oliver's hair cut. Thank you TLC Pets. Great work. Will recommend and use in the future."
    },
    {
        name: 'Stay See',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_b3327e27829dfdc1a6d1292d4b189541.jpg',
        date: '1677865335',
        url: 'https://www.google.com/maps/contrib/116622356401084851830/reviews',
        rev: "Mobile Canine Teeth Cleaning 5 stars!!!!I am beyond grateful to TLC and Vanessa. My 18 year old Yorkie desperately needed his teeth cleaned (1k). The vet suggested anesthesia and scaling which made me too nervous since he is so fragile. Vanessa showed up early, was very personable and provided my pup with VIP care. I couldn't get over the difference. His mouth was really bothering him and me since I knew how uncomfortable he was. I provided him with a little traxadone from the vet to make it easy for both the tech and pup. This organization is top notch with all their amazing services. Thank you TLC and staff. We love you."
    },
    {
        name: 'Crystal Benitez',
        image: 'ChIJ7YMuFS4I2YgR2S_O2piSCv0_c43f1a9887f94981a40a6bc2c7a956da.jpg',
        date: '1641679341',
        url: 'https://www.google.com/maps/contrib/105089683947805201846/reviews',
        rev: 'Extremely impressed with TLC per services. I will for sure be bringing him back. I had an appointment with another place to have my dog groomed and they canceled last minute. It was very important that I get my dog Benji in that day being that he had very bad knots on his legs and a bunch of other places. Which can be very painful..I called Tlc explained to them the situation and they were able to fit me in that day and were so friendly and accommodating they told me I could bring him in right away. I was amazed at how well they did and reasonably priced they were. The woman who groomed him was amazing and sweet. Although she only spoke a little English I made sure to let her know how appreciative I was of her and you can tell how much she cares about the animals. She was very genuine!! I will for sure recommend this place and be back. They took such good care of my baby. Here’s a before and after picture of Benji'
    },
];