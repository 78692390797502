import './App.css';
import SeccionFormulario from './components/SeccionFormulario';
import SeccionResenas from './components/SeccionResenas';
import SeccionFaqs from './components/SeccionFaqs';
import SeccionCaruselPets from './components/SeccionCaruselPets';
import "react-datepicker/dist/react-datepicker.css";
import SeccionFormulario2 from './components/SeccionFormulario2';
import imgBan from './assets/images/furgoneta.webp'

function App() {
  return (
    <div className="App">
      <SeccionFormulario />
      <img className="img-ban-movile" src={ imgBan } alt='TLC Grooming'/>
      <SeccionResenas/>
      <SeccionFaqs />
      <SeccionCaruselPets />
      <SeccionFormulario2/>
    </div>
  );
}

export default App;
