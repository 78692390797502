import { useState } from "react"
import { Bounce } from "react-awesome-reveal";

export default function HiperText() {

    const [showText, setShowText] = useState(false)

    return <div className="cont-prev-hipertext">
        <div className="cont-hiper-text">
            <h2 className="text-about-us" onClick={() => setShowText(true)}>
                <svg width="59" height="62" viewBox="0 0 59 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M23.203 20.3327C19.763 18.9746 18.5632 14.4865 20.5232 10.3082C22.4832 6.12987 26.8607 3.84361 30.3007 5.20168C33.7407 6.55976 34.9405 11.0479 32.9805 15.2262C31.0205 19.4045 26.643 21.6908 23.203 20.3327Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M16.9029 22.7249C16.2947 25.9402 13.4642 28.1258 10.5709 27.6007C7.68345 27.0838 5.83334 24.0459 6.43416 20.8292C7.04241 17.6139 9.87291 15.4283 12.7662 15.9534C15.6595 16.4786 17.5038 19.5083 16.9029 22.7249Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M39.9502 27.7975C36.5103 26.4395 35.3105 21.9513 37.2704 17.773C39.2304 13.5947 43.608 11.3085 47.048 12.6665C50.4879 14.0246 51.6877 18.5127 49.7278 22.691C47.7678 26.8693 43.3902 29.1556 39.9502 27.7975Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M41.3526 41.4151C39.1647 39.6016 39.2743 36.1429 41.5976 33.6898C43.9208 31.2367 47.5777 30.7182 49.7657 32.5317C51.9536 34.3451 51.8439 37.8039 49.5207 40.257C47.1975 42.7101 43.5405 43.2286 41.3526 41.4151Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M15.8695 54.6598C15.4518 54.4766 14.9968 54.2218 14.5534 53.8823C13.5541 53.1255 13.0873 52.2833 12.1857 50.6264C11.8082 49.9303 11.3456 49.0022 10.9099 47.855C10.263 47.7031 9.17475 47.371 8.07407 46.5888C7.63906 46.2797 6.27465 45.2769 5.6075 43.5319C4.84693 41.5246 5.47688 39.7662 5.77526 38.9599C7.04671 35.4597 10.4457 33.9153 11.4871 33.4443C12.3161 33.0653 13.7449 32.4397 15.7431 32.4073C17.241 32.3864 18.4459 32.7105 19.1989 32.9681C19.4734 32.7716 19.8766 32.4465 20.2395 31.9624C20.7123 31.3318 20.8818 30.7553 20.9407 30.5635C21.415 29.1529 24.3096 26.225 27.7782 26.5281C29.8882 26.7108 31.2793 28.0002 31.4405 28.1663C33.8796 30.503 33.3064 34.4678 32.4512 35.7018C32.3366 35.8691 31.9664 36.3519 31.75 37.1006C31.5821 37.6702 31.5687 38.1663 31.5859 38.4945C32.2721 38.8774 33.305 39.5536 34.2342 40.6519C35.4764 42.1241 35.8678 43.5669 36.0824 44.4072C36.3541 45.4673 37.2375 48.9198 35.1674 52.0603C34.6887 52.7838 33.6484 54.3603 31.5174 55.077C29.6609 55.6983 27.974 55.3385 27.4432 55.2146C26.1037 54.9097 25.1417 54.3257 24.6074 53.9483C23.39 54.3517 22.3282 54.6095 21.5261 54.7694C19.5992 55.1613 18.6137 55.3533 17.353 55.106C17.0881 55.0585 16.5353 54.9307 15.8759 54.632L15.8695 54.6598Z" fill="#FFA1BD" />
                    </g>
                </svg>
                <div className="cont-about">
                    <p>About Us</p>
                    <span className="read-more">read more...</span>
                </div>
                <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M20.1575 11.0834C22.2479 10.7946 23.6758 8.53036 23.3469 6.02607C23.018 3.52179 21.0569 1.72579 18.9665 2.01459C16.8762 2.3034 15.4482 4.56765 15.7771 7.07193C16.106 9.57622 18.0672 11.3722 20.1575 11.0834Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M23.1364 13.1842C22.9045 14.9953 24.0494 16.5378 25.7001 16.6275C27.3462 16.7208 28.8722 15.3219 29.1084 13.511C29.3403 11.6998 28.1954 10.1573 26.5446 10.0676C24.8939 9.97798 23.3726 11.3732 23.1364 13.1842Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M9.83242 12.9494C11.9228 12.6606 13.3507 10.3964 13.0218 7.8921C12.6929 5.38781 10.7317 3.59181 8.64138 3.88062C6.55104 4.16943 5.1231 6.43367 5.45199 8.93796C5.78089 11.4422 7.74207 13.2382 9.83242 12.9494Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M6.70596 20.1077C8.20081 19.4121 8.74383 17.534 7.91884 15.9127C7.09385 14.2914 5.21325 13.5409 3.71841 14.2364C2.22356 14.9319 1.68054 16.8101 2.50553 18.4314C3.33052 20.0527 5.21112 20.8032 6.70596 20.1077Z" fill="#FFA1BD" />
                    </g>
                    <g>
                        <path d="M18.1338 30.528C18.3908 30.4831 18.6804 30.4044 18.9785 30.2786C19.6487 29.9994 20.0469 29.6057 20.8213 28.8289C21.1459 28.5023 21.5568 28.0617 21.9913 27.4996C22.3664 27.501 23.0107 27.4622 23.7401 27.1824C24.0283 27.0719 24.9382 26.7072 25.6016 25.8527C26.3609 24.8689 26.3275 23.8401 26.3071 23.3671C26.2312 21.317 24.6682 20.0468 24.189 19.6588C23.8082 19.3478 23.1471 18.8266 22.0758 18.5517C21.2722 18.3476 20.5664 18.367 20.1158 18.4089C20.0021 18.2676 19.8413 18.0404 19.73 17.7328C19.585 17.3321 19.594 16.9995 19.5956 16.8886C19.5856 16.0673 18.5353 14.1165 16.6133 13.8331C15.4443 13.6597 14.4701 14.1754 14.3543 14.2442C12.633 15.1893 12.2517 17.3999 12.4978 18.1751C12.5304 18.2801 12.6459 18.5879 12.6322 19.0193C12.6235 19.3479 12.5444 19.617 12.478 19.7917C12.0415 19.9096 11.3671 20.141 10.6751 20.6132C9.74933 21.2467 9.28724 21.9738 9.02532 22.399C8.69433 22.9353 7.6172 24.6822 8.18612 26.6414C8.31811 27.0929 8.60432 28.0766 9.62801 28.7373C10.5204 29.3113 11.4922 29.3346 11.7998 29.3362C12.5748 29.3445 13.1949 29.1537 13.5486 29.0191C14.1344 29.3933 14.6618 29.669 15.0662 29.8585C16.0364 30.3179 16.5341 30.5483 17.2566 30.5774C17.4076 30.586 17.7278 30.5883 18.1352 30.5123L18.1338 30.528Z" fill="#FFA1BD" />
                    </g>
                </svg>
            </h2>
            {showText && <Bounce>
                <p className="text-about-content">TLC Mobile Pet Grooming has more than 20 years of combined experience in the pet industry. Our mission is to provide the best pet grooming services in Palm Beach and Broward Counties. Our top priorities are safety for your pets, peace of mind for you and great dog & cat grooming services!</p>
            </Bounce>}
        </div>
    </div>
}