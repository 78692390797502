export const preciosTlcPrincipal = {
  Small: {
    basicGrooming: { price: 62, estimated: '$62' },
    deShedding: { price: 75, estimated: '$62 - $75' },
    fullGrooming: { price: 75, estimated: '$62 - $75' }
  },
  Medium: {
    basicGrooming: { price: 78, estimated: '$78' },
    deShedding: { price: 90, estimated: '$78 - $90' },
    fullGrooming: { price: 90, estimated: '$78 - $90' }
  },
  Large: {
    basicGrooming: { price: 94, estimated: '$94' },
    deShedding: { price: 110, estimated: '$94 - $110' },
    fullGrooming: { price: 110, estimated: '$94 - $110' }
  },
  Xlarge: {
    basicGrooming: { price: 120, estimated: '$120' },
    deShedding: { price: 130, estimated: '$120 - $130' },
    fullGrooming: { price: 130, estimated: '$120 - $130' },
  }
}

export const preciosTlcPalmBeach = {
  Small: {
    basicGrooming: { price: 65, estimated: '$65' },
    deShedding: { price: 85, estimated: '$65 - $85' },
    fullGrooming: { price: 85, estimated: '$65 - $85' }
  },
  Medium: {
    basicGrooming: { price: 80, estimated: '$80' },
    deShedding: { price: 100, estimated: '$80 - $100' },
    fullGrooming: { price: 95, estimated: '$80 - $95' }
  },
  Large: {
    basicGrooming: { price: 100, estimated: '$100' },
    deShedding: { price: 130, estimated: '$100 - $120' },
    fullGrooming: { price: 130, estimated: '$100 - $130' }
  },
  Xlarge: {
    basicGrooming: { price: 120, estimated: '$120' },
    deShedding: { price: 140, estimated: '$120 - $140' },
    fullGrooming: { price: 145, estimated: '$120 - $145' }
  }
}