export default function SeccionFaqs() {
    return <div className="seccion-faqs">
        <p className="title-faqs">FAQs and Reasons to Choose TLC</p>
        <div className="container-faqs">
            <div className="faq">
                <div className="back-faq"></div>
                <div className="title-faq">
                    <span>Hygiene and Safety</span>
                    <div className="cont-svg-chek">
                        <svg width="35" height="35" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5" cy="24.5" r="24.5" fill="#1DBD40" />
                            <path d="M18.1891 41.672C18.1891 41.33 18.1511 41.178 17.9991 41.178L17.1251 41.596C17.1251 41.406 17.0111 41.292 16.8211 41.216L16.5171 41.178C16.2511 41.178 16.1371 41.216 15.7571 41.444C15.6431 41.216 15.4911 40.95 15.3771 40.722C14.3891 38.822 13.3631 35.972 12.9071 34.718C12.6791 34.072 12.4511 32.742 12.1851 30.728C12.4891 30.918 12.7171 30.994 12.8311 30.994C12.9831 30.994 13.1731 30.728 13.3251 30.196C13.4011 30.31 13.5531 30.348 13.7431 30.348C13.8571 30.348 14.0091 30.31 14.0851 30.196L14.6931 29.284L15.3771 29.512H15.4151C15.4911 29.512 15.6051 29.398 15.7951 29.284C15.9851 29.17 16.1371 29.094 16.2511 29.094L16.3651 29.132C16.9731 29.436 17.3911 29.968 17.5811 30.804C18.0371 32.742 18.4551 33.692 18.9491 33.692C19.3671 33.692 19.9371 33.198 20.5451 32.248C21.1531 31.298 21.7611 30.006 22.4451 28.448C22.4831 28.752 22.5211 28.904 22.5971 28.904C22.8251 28.904 23.3951 27.574 24.8011 25.332C26.8911 21.95 32.0591 15.452 33.3891 14.54C34.3771 13.856 35.1371 13.21 35.6691 12.64C35.5931 13.02 35.5171 13.286 35.5171 13.4C35.5171 13.514 35.5931 13.552 35.6691 13.552L36.7331 13.02V13.172C36.7331 13.362 36.7711 13.476 36.8851 13.476C37.0371 13.476 37.6451 12.868 37.7211 12.64L37.6451 13.172L38.9371 12.412L38.6331 13.096C39.0131 12.83 39.3171 12.678 39.5071 12.678C39.6971 12.678 39.8111 12.982 39.8111 13.172C39.8111 13.476 39.5451 13.894 39.1271 14.426C38.6711 15.034 37.5311 16.212 34.1111 20.126C32.6291 21.798 26.0551 30.728 24.8011 32.856L22.4451 36.846C21.4191 38.556 20.7731 39.658 20.4311 40.076C20.0891 40.494 19.6711 40.912 19.1771 41.292L18.8351 41.102L18.5311 41.292L18.1891 41.672Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <p className="sub-title">Is TLC a safe place for my fur baby?</p>
                <p className="faq-text-info">At TLC, your fur baby's safety is our top priority. Our groomers are properly trained to care for your beloved pet. Safety precautions are taken during the grooming process, from dry floors to sanitized professional grooming tools, ensuring a safe and comfortable experience for your furry friend.</p>
            </div>
            <div className="faq">
                <div className="back-faq"></div>
                <div className="title-faq">
                    <span>High-Quality Products</span>
                    <div className="cont-svg-chek">
                        <svg width="35" height="35" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5" cy="24.5" r="24.5" fill="#1DBD40" />
                            <path d="M18.1891 41.672C18.1891 41.33 18.1511 41.178 17.9991 41.178L17.1251 41.596C17.1251 41.406 17.0111 41.292 16.8211 41.216L16.5171 41.178C16.2511 41.178 16.1371 41.216 15.7571 41.444C15.6431 41.216 15.4911 40.95 15.3771 40.722C14.3891 38.822 13.3631 35.972 12.9071 34.718C12.6791 34.072 12.4511 32.742 12.1851 30.728C12.4891 30.918 12.7171 30.994 12.8311 30.994C12.9831 30.994 13.1731 30.728 13.3251 30.196C13.4011 30.31 13.5531 30.348 13.7431 30.348C13.8571 30.348 14.0091 30.31 14.0851 30.196L14.6931 29.284L15.3771 29.512H15.4151C15.4911 29.512 15.6051 29.398 15.7951 29.284C15.9851 29.17 16.1371 29.094 16.2511 29.094L16.3651 29.132C16.9731 29.436 17.3911 29.968 17.5811 30.804C18.0371 32.742 18.4551 33.692 18.9491 33.692C19.3671 33.692 19.9371 33.198 20.5451 32.248C21.1531 31.298 21.7611 30.006 22.4451 28.448C22.4831 28.752 22.5211 28.904 22.5971 28.904C22.8251 28.904 23.3951 27.574 24.8011 25.332C26.8911 21.95 32.0591 15.452 33.3891 14.54C34.3771 13.856 35.1371 13.21 35.6691 12.64C35.5931 13.02 35.5171 13.286 35.5171 13.4C35.5171 13.514 35.5931 13.552 35.6691 13.552L36.7331 13.02V13.172C36.7331 13.362 36.7711 13.476 36.8851 13.476C37.0371 13.476 37.6451 12.868 37.7211 12.64L37.6451 13.172L38.9371 12.412L38.6331 13.096C39.0131 12.83 39.3171 12.678 39.5071 12.678C39.6971 12.678 39.8111 12.982 39.8111 13.172C39.8111 13.476 39.5451 13.894 39.1271 14.426C38.6711 15.034 37.5311 16.212 34.1111 20.126C32.6291 21.798 26.0551 30.728 24.8011 32.856L22.4451 36.846C21.4191 38.556 20.7731 39.658 20.4311 40.076C20.0891 40.494 19.6711 40.912 19.1771 41.292L18.8351 41.102L18.5311 41.292L18.1891 41.672Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <p className="sub-title">What products does TLC use?</p>
                <p className="faq-text-info">We exclusively utilize top-tier brands like Bio-groom and Davis to provide hypoallergenic and oatmeal shampoos suitable for all types of skin. For the most effective de-shedding results, we trust the Furminator brand shampoo.</p>
            </div>
            <div className="faq">
                <div className="back-faq"></div>
                <div className="title-faq">
                    <span>Personalized Care and Attention</span>
                    <div className="cont-svg-chek">
                        <svg width="35" height="35" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5" cy="24.5" r="24.5" fill="#1DBD40" />
                            <path d="M18.1891 41.672C18.1891 41.33 18.1511 41.178 17.9991 41.178L17.1251 41.596C17.1251 41.406 17.0111 41.292 16.8211 41.216L16.5171 41.178C16.2511 41.178 16.1371 41.216 15.7571 41.444C15.6431 41.216 15.4911 40.95 15.3771 40.722C14.3891 38.822 13.3631 35.972 12.9071 34.718C12.6791 34.072 12.4511 32.742 12.1851 30.728C12.4891 30.918 12.7171 30.994 12.8311 30.994C12.9831 30.994 13.1731 30.728 13.3251 30.196C13.4011 30.31 13.5531 30.348 13.7431 30.348C13.8571 30.348 14.0091 30.31 14.0851 30.196L14.6931 29.284L15.3771 29.512H15.4151C15.4911 29.512 15.6051 29.398 15.7951 29.284C15.9851 29.17 16.1371 29.094 16.2511 29.094L16.3651 29.132C16.9731 29.436 17.3911 29.968 17.5811 30.804C18.0371 32.742 18.4551 33.692 18.9491 33.692C19.3671 33.692 19.9371 33.198 20.5451 32.248C21.1531 31.298 21.7611 30.006 22.4451 28.448C22.4831 28.752 22.5211 28.904 22.5971 28.904C22.8251 28.904 23.3951 27.574 24.8011 25.332C26.8911 21.95 32.0591 15.452 33.3891 14.54C34.3771 13.856 35.1371 13.21 35.6691 12.64C35.5931 13.02 35.5171 13.286 35.5171 13.4C35.5171 13.514 35.5931 13.552 35.6691 13.552L36.7331 13.02V13.172C36.7331 13.362 36.7711 13.476 36.8851 13.476C37.0371 13.476 37.6451 12.868 37.7211 12.64L37.6451 13.172L38.9371 12.412L38.6331 13.096C39.0131 12.83 39.3171 12.678 39.5071 12.678C39.6971 12.678 39.8111 12.982 39.8111 13.172C39.8111 13.476 39.5451 13.894 39.1271 14.426C38.6711 15.034 37.5311 16.212 34.1111 20.126C32.6291 21.798 26.0551 30.728 24.8011 32.856L22.4451 36.846C21.4191 38.556 20.7731 39.658 20.4311 40.076C20.0891 40.494 19.6711 40.912 19.1771 41.292L18.8351 41.102L18.5311 41.292L18.1891 41.672Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <p className="sub-title">Will my pet be in good hands?</p>
                <p className="faq-text-info">Our groomers are passionate pet lovers and proud pet owners. They will groom your fur baby with love and care, ensuring their comfort and well-being.</p>
            </div>
            <div className="faq">
                <div className="back-faq"></div>
                <div className="title-faq">
                    <span>Mobile Service and Convenience</span>
                    <div className="cont-svg-chek">
                        <svg width="35" height="35" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5" cy="24.5" r="24.5" fill="#1DBD40" />
                            <path d="M18.1891 41.672C18.1891 41.33 18.1511 41.178 17.9991 41.178L17.1251 41.596C17.1251 41.406 17.0111 41.292 16.8211 41.216L16.5171 41.178C16.2511 41.178 16.1371 41.216 15.7571 41.444C15.6431 41.216 15.4911 40.95 15.3771 40.722C14.3891 38.822 13.3631 35.972 12.9071 34.718C12.6791 34.072 12.4511 32.742 12.1851 30.728C12.4891 30.918 12.7171 30.994 12.8311 30.994C12.9831 30.994 13.1731 30.728 13.3251 30.196C13.4011 30.31 13.5531 30.348 13.7431 30.348C13.8571 30.348 14.0091 30.31 14.0851 30.196L14.6931 29.284L15.3771 29.512H15.4151C15.4911 29.512 15.6051 29.398 15.7951 29.284C15.9851 29.17 16.1371 29.094 16.2511 29.094L16.3651 29.132C16.9731 29.436 17.3911 29.968 17.5811 30.804C18.0371 32.742 18.4551 33.692 18.9491 33.692C19.3671 33.692 19.9371 33.198 20.5451 32.248C21.1531 31.298 21.7611 30.006 22.4451 28.448C22.4831 28.752 22.5211 28.904 22.5971 28.904C22.8251 28.904 23.3951 27.574 24.8011 25.332C26.8911 21.95 32.0591 15.452 33.3891 14.54C34.3771 13.856 35.1371 13.21 35.6691 12.64C35.5931 13.02 35.5171 13.286 35.5171 13.4C35.5171 13.514 35.5931 13.552 35.6691 13.552L36.7331 13.02V13.172C36.7331 13.362 36.7711 13.476 36.8851 13.476C37.0371 13.476 37.6451 12.868 37.7211 12.64L37.6451 13.172L38.9371 12.412L38.6331 13.096C39.0131 12.83 39.3171 12.678 39.5071 12.678C39.6971 12.678 39.8111 12.982 39.8111 13.172C39.8111 13.476 39.5451 13.894 39.1271 14.426C38.6711 15.034 37.5311 16.212 34.1111 20.126C32.6291 21.798 26.0551 30.728 24.8011 32.856L22.4451 36.846C21.4191 38.556 20.7731 39.658 20.4311 40.076C20.0891 40.494 19.6711 40.912 19.1771 41.292L18.8351 41.102L18.5311 41.292L18.1891 41.672Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <p className="sub-title">Tired of stressful car rides to the groomer?</p>
                <p className="faq-text-info">Our mobile service brings convenience right to your door. Save time and get your fur baby groomed in about an hour or so. Book Now!</p>
            </div>
        </div>
    </div>
}