import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import '../assets/css/resenas.css'
import { dataReviews } from '../providers/dataReviews';
import Moment from 'react-moment';

export default function Resenas() {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return <div className="cont-resena">
        <div className="grw-header">
            <div className="grw-header-inner">
                <div className="wp-google-place">
                    <div className="wp-google-left-h">
                        <img decoding="async" src="https://lh3.googleusercontent.com/places/ANXAkqEAFojRmK-1dF5_tkuDYHzdnI4ncoZWnj7b95LI44zgpNLNN3vOxVkwU2Z4dmvrFHJx3JbaukRLWzIUKjCNxBMR990XGkQuRg4=s1600-w300-h300" alt="TLC Pet Care Services" width="50" height="50" title="TLC Pet Care Services" />
                    </div>
                    <div className="wp-google-right">
                        <div className="wp-google-name">
                            <a href="https://maps.google.com/?cid=18233547226566504409" target="_blank" rel="noreferrer">
                                <span>TLC Pet Care Services</span>
                            </a>
                        </div>
                        <div>
                            <span className="wp-google-rating">4.5</span>
                            <span className="wp-google-stars">
                                <span className="wp-stars">
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1250 957l257-250-356-52-66-10-30-60-159-322v963l59 31 318 168-60-355-12-66zm452-262l-363 354 86 500q5 33-6 51.5t-34 18.5q-17 0-40-12l-449-236-449 236q-23 12-40 12-23 0-34-18.5t-6-51.5l86-500-364-354q-32-32-23-59.5t54-34.5l502-73 225-455q20-41 49-41 28 0 49 41l225 455 502 73q45 7 54 34.5t-24 59.5z" fill="#fb8e28"></path></svg>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="wp-google-powered">Based on 348 reviews</div>
                        <div className="wp-google-powered">
                            <img decoding="async" src="https://tlc-pcs.com/wp-content/plugins/widget-google-reviews/assets/img/powered_by_google_on_white.png" alt="powered by Google" width="144" height="18" title="powered by Google" />
                        </div>
                        <div className="wp-google-wr">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJ7YMuFS4I2YgR2S_O2piSCv0">review us on<svg viewBox="0 0 512 512" height="18" width="18"><g fill="none" fillRule="evenodd"><path d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z" fill="#4285f4"></path><path d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z" fill="#34a853"></path><path d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z" fill="#fbbc05"></path><path d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z" fill="#ea4335"></path><path d="M20 20h472v472H20V20z"></path></g></svg></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-carusel-resenas'>
                <Carousel responsive={responsive} arrows={ false } infinite={true} autoPlay={ true } autoPlaySpeed={ 2000 } >
                    { dataReviews.map((review, index) => <div key={ index } className="grw-review">
                        <div className="grw-review-inner grw-backgnd">
                            <div className="wp-google-left">
                                <img loading="lazy" decoding="async" src={require(`../assets/images/reviews/${ review.image }`)} className="rplg-review-avatar rplg-blazy b-loaded" alt={ review.image } width="50" height="50" title={ review.name }/>
                                    <div className="cont-title-google-wp">
                                        <a href={ review.url } className="wp-google-name" target="_blank" rel="noreferrer">{ review.name.slice(0, 15) }</a>
                                        <div className="wp-google-time"><Moment unix fromNow>{ review.date }</Moment></div>
                                    </div>
                            </div>
                            <div className="wp-google-stars">
                                <span className="wp-stars">
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                    <span className="wp-star">
                                        <svg width="17" height="17" viewBox="0 0 1792 1792"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#fb8e28"></path></svg>
                                    </span>
                                </span>
                            </div>
                            <div>
                                <div className="wp-google-feedback">
                                    <span className="wp-google-text">{ review.rev }</span>
                                </div>
                            </div>
                            <svg viewBox="0 0 512 512" height="18" width="18"><g fill="none" fillRule="evenodd"><path d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z" fill="#4285f4"></path><path d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z" fill="#34a853"></path><path d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z" fill="#fbbc05"></path><path d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z" fill="#ea4335"></path><path d="M20 20h472v472H20V20z"></path></g></svg>
                        </div>
                    </div>)}
                </Carousel>
            </div>
        </div>
    </div>
}